<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("mainNav.rejectReasons") }}</h1>

    <reject-reasons />
  </article>
</template>

<script>
import RejectReasons from "../components/rejectreason/RejectReasons";

export default {
  name: "RejectReasonsMgmt",
  components: {
    RejectReasons,
  },
};
</script>

<style scoped></style>
