<template>
  <fragment>
    <header class="action-container">
      <v-row>
        <v-col cols="12" md="6" lg="5" xl="4" class="order-2 order-md-1">
          <wx-text-field
            v-model="searchKeyword"
            :label="$t('common.search')"
            :change="checkFilterResult"
            prepend-inner-icon="mdi-magnify"
            hide-details
            single-line
            clearable
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="7"
          xl="8"
          class="action-buttons order-1 order-md-2 d-flex flex-wrap justify-center justify-md-end align-md-end"
        >
          <!-- 
            Create Reason - - - - - - - - - - - - - - -
            -->
          <wx-btn-standard :to="getReasonCreationUrl()" :disabled="!enableNewReasonButton" class="mx-1 mb-2 mb-md-1">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("rejectReason.wizard.reasonCreation.buttonNew") }}
          </wx-btn-standard>

          <!-- 
            Create Category - - - - - - - - - - - -
            -->
          <wx-btn-standard
            :to="getCategoryCreationUrl()"
            :disabled="!enableNewCategoryButton"
            class="mx-1 mb-2 mb-md-1"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ $t("rejectReason.wizard.categoryCreation.buttonNew") }}
          </wx-btn-standard>

          <!-- 
            Edit - - - - - - - - - - - - - - -
            -->
          <wx-btn-standard
            :to="getEditionUrl()"
            :disabled="!enableEditReasonButton && !enableEditCategoryButton"
            class="mx-1 mb-2 mb-md-1"
          >
            <v-icon left>mdi-pencil</v-icon>
            {{ $t("rejectReason.wizard.reasonEdition.buttonNew") }}
          </wx-btn-standard>

          <!-- 
            Delete - - - - - - - - - - - - -
            -->
          <dialog-confirm-deletion
            :deletion-title="getDeletionTitle()"
            :info-box-text-title="$t('common.attention')"
            :info-box-text="getDeletionInfoText()"
            :confirm-text-prefix="getDeletionConfirmationPrefix()"
            :element-display-name="getObjectToDeleteDisplayName()"
            :disabled="!enableDeleteButton"
            :delete-button-text="getDeleteButtonText()"
            @confirm="deleteSelected()"
            activator-btn-class="mx-1 mb-2 mb-md-1"
          />
        </v-col>
      </v-row>
    </header>

    <div class="finder-container">
      <finder
        ref="myFinder"
        :tree="tree"
        :theme="finderTheme"
        :item-component="itemComponent"
        :arrow-component="arrowComponent"
        :filter="(item) => this.filterTree(item)"
        :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
        @expand="onExpand"
      >
      </finder>
      <wx-alert-query-message
        v-if="noResult"
        :text="$t('common.noMatchingRecords')"
        container-css-class="pa-4 rounded-lg"
        no-results-icon
        bordered
      />
    </div>
  </fragment>
</template>

<script>
import { Finder } from "@jledentu/vue-finder";
import { mapActions, mapGetters } from "vuex";
import DialogConfirmDeletion from "@/components/DialogConfirmDeletion";
import ErrorHandling from "@/components/ErrorHandling";
import RejectReasonService from "@/components/rejectreason/RejectReasonService";
import RouteService from "@/router/RouteService";
import StyleValues from "@/styles/_values.scss";
import WxAlertQueryMessage from "@/components/ui/WxAlertQueryMessage";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxTextField from "@/components/ui/WxTextField";
import "@jledentu/vue-finder/dist/vue-finder.css";

export default {
  name: "RejectReasons",
  components: {
    DialogConfirmDeletion,
    Finder,
    WxAlertQueryMessage,
    WxBtnStandard,
    WxTextField,
  },
  data() {
    return {
      noResult: false,
      searchKeyword: "",
      tree: {
        id: "root",
        children: [],
      },
      categoryById: new Map(),

      selectedElement: {},
      selectedElementParent: {},
      selectedElementChain: [],

      enableNewReasonButton: false,
      enableNewCategoryButton: false,
      enableDeleteButton: false,
      enableEditReasonButton: false,
      enableEditCategoryButton: false,

      itemComponent: {
        props: ["item"],
        template:
          "<div>" +
          " <v-icon>{{ (item.type === 'category' ) ? 'mdi-folder-outline' : '' }}</v-icon>" +
          " {{ item.code }}" +
          " - " +
          "{{ item.name }}" +
          "</div>",
      },
      arrowComponent: {
        props: ["expanded", "item", "theme"],
        template: "<v-icon>mdi-menu-right</v-icon>", // eslint-disable-line @intlify/vue-i18n/no-raw-text
      },
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    ...mapGetters("user", ["theme"]),
    finderTheme() {
      let theme = {
        primaryColor: this.theme === "light" ? StyleValues.primaryLight : StyleValues.primaryDark,
        arrowColor: this.theme === "light" ? StyleValues.color_text_themeLight : StyleValues.color_text_themeDark,
        separatorColor:
          this.theme === "light" ? StyleValues.color_border_themeLight : StyleValues.color_border_themeDark,
        separatorWidth: "1px",
      };
      return theme;
    },
  },
  watch: {
    activeFactory() {
      this.searchKeyword = "";
      this.tree = {
        id: "root",
        children: [],
      };
      this.categoryById = new Map();
      this.selectedElement = {};
      this.selectedElementParent = {};
      this.selectedElementChain = [];
      this.loadTree();
    },
  },
  methods: {
    ...mapActions("dashboard", ["fetchAvailableRejectReasons"]),
    ...mapActions("operation", ["showOperationSuccess", "showOperationError"]),
    checkFilterResult() {
      this.$nextTick(function() {
        let noResult = this.$refs.myFinder.treeModel.filtered.length <= 1;
        if (noResult) {
          // Nothing matches the search criteria, hide the buttons
          this.enableNewReasonButton = false;
          this.enableNewCategoryButton = false;
          this.enableDeleteButton = false;
          this.enableEditReasonButton = false;
          this.enableEditCategoryButton = false;
        }
        if (this.selectedElement.id) {
          if (this.selectedElement.level === 1) {
            // Root category. Disable the Edit & Deletion buttons
            this.enableDeleteButton = false;
            this.enableEditCategoryButton = false;
          } else {
            // Child category. Enable the Edit & Deletion buttons
            this.enableDeleteButton = true;
            this.enableEditCategoryButton = true;
            const selectedElementIsFilteredIn = this.$refs.myFinder.treeModel.filtered.includes(
              this.selectedElement.id,
            );
            if (!selectedElementIsFilteredIn) {
              // The current selected element does not match the search criteria: unselect it; expand only the first column ("root")
              this.selectedElement = {};
              this.selectedElementParent = {};
              this.selectedElementChain = [];
              this.$refs.myFinder.expand("root");
            }
          }
        }
      });
    },
    loadTree() {
      if (this.activeFactory) {
        return RejectReasonService.getRejectReasonTree(this.activeFactory.id)
          .then((response) => this.handleRejectReasonTreeRetrievalResponse(response))
          .catch((error) => this.handleRejectReasonTreeRetrievalError(error.response));
      }
    },
    handleRejectReasonTreeRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.tree = {
          id: httpResponse.data.id,
          type: "category",
          level: 0,
          children: httpResponse.data.children,
        };
        let categoryMap = new Map();
        this.buildCategoryById(httpResponse.data.children, categoryMap);
        this.categoryById = categoryMap;
      } else {
        this.handleRejectReasonTreeRetrievalError(httpResponse);
      }
    },

    buildCategoryById(children, destinationMap) {
      children.forEach((element) => {
        if (element.type === "category") {
          destinationMap[element.id] = { id: element.id, code: element.code, name: element.name };
          if (element.children.length > 0) {
            this.buildCategoryById(element.children, destinationMap);
          }
        }
      });
    },

    handleRejectReasonTreeRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },

    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },

    onExpand({ expanded }) {
      this.enableNewReasonButton = false;
      this.enableNewCategoryButton = false;
      this.enableDeleteButton = false;
      this.enableEditReasonButton = false;
      this.enableEditCategoryButton = false;
      this.selectedElementChain = expanded.filter((el) => el !== "root").map((el) => this.searchTree(this.tree, el));
      if (this.selectedElementChain.length > 0) {
        this.selectedElement = this.selectedElementChain[this.selectedElementChain.length - 1];
        this.$router.replace({ query: { expand: this.selectedElement.id } });
        if (this.selectedElementChain.length >= 2) {
          this.selectedElementParent = this.selectedElementChain[this.selectedElementChain.length - 2];
        }
        if (this.selectedElement) {
          if (this.selectedElementChain.length === 1) {
            // Root category. Disable the Edit & Deletion buttons
            this.enableDeleteButton = false;
            this.enableEditCategoryButton = false;
            this.enableNewCategoryButton = true;
            this.enableNewReasonButton = true;
          } else {
            this.enableDeleteButton = true;

            if (this.selectedElement.type === "category") {
              // Allowed to create a new reason under the selected category
              this.enableNewReasonButton = true;
              this.enableNewCategoryButton = true;
              this.enableEditCategoryButton = true;
            } else {
              this.enableEditReasonButton = true;
            }
          }
        }
      }
    },

    searchTree(element, matchingId) {
      if (element.id === matchingId) {
        return element;
      } else if (element.children != null) {
        let i;
        let result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.searchTree(element.children[i], matchingId);
        }
        return result;
      }
      return null;
    },

    filterTree(treeItem) {
      if (this.searchKeyword && this.searchKeyword.length > 0 && (treeItem.code || treeItem.name)) {
        const codeWithName = treeItem.code.toLowerCase() + " - " + treeItem.name.toLowerCase();
        return codeWithName.includes(this.searchKeyword);
      }
      return true;
    },

    getDeletionTitle() {
      if (this.selectedElement) {
        if (this.selectedElement.type === "category") {
          return this.$t("rejectReason.wizard.categoryDeletion.title");
        } else {
          return this.$t("rejectReason.wizard.reasonDeletion.title");
        }
      }
      return "";
    },
    getDeleteButtonText() {
      if (this.selectedElement) {
        if (this.selectedElement.type === "category") {
          return this.$t("rejectReason.wizard.categoryDeletion.deleteRejectCategoryBtn");
        } else {
          return this.$t("rejectReason.wizard.reasonDeletion.deleteRejectReasonBtn");
        }
      }
      return "";
    },

    getDeletionInfoText() {
      if (this.selectedElement) {
        if (this.selectedElement.type === "category") {
          let counters = this.getSubCategoryAndReasonCounters(this.selectedElement);
          return this.$t("rejectReason.wizard.categoryDeletion.warning", {
            subCategoryCount: counters.category,
            reasonCount: counters.reason,
          });
        } else {
          return this.$t("rejectReason.wizard.reasonDeletion.warning");
        }
      }
      return "";
    },

    getSubCategoryAndReasonCounters(fromCategory) {
      let counters = { category: 0, reason: 0 };
      if (fromCategory.children) {
        let i;
        for (i = 0; i < fromCategory.children.length; i++) {
          let element = fromCategory.children[i];
          if (element.type === "category") {
            counters.category++;

            let thisSubCategoryCounters = this.getSubCategoryAndReasonCounters(element);
            counters.category += thisSubCategoryCounters.category;
            counters.reason += thisSubCategoryCounters.reason;
          }
          if (element.type === "reason") {
            counters.reason++;
          }
        }
      }
      return counters;
    },

    getDeletionConfirmationPrefix() {
      if (this.selectedElement) {
        if (this.selectedElement.type === "category") {
          return this.$t("rejectReason.wizard.categoryDeletion.confirmationPrefix");
        } else {
          return this.$t("rejectReason.wizard.reasonDeletion.confirmationPrefix");
        }
      }
      return "";
    },

    getObjectToDeleteDisplayName() {
      if (this.selectedElement) {
        return this.selectedElement.code + " - " + this.selectedElement.name;
      }
      return "";
    },

    deleteSelected() {
      if (this.selectedElement) {
        if (this.selectedElement.type === "category") {
          this.deleteCategory(this.selectedElement);
        } else {
          this.deleteReason(this.selectedElement);
        }
      }
      return "";
    },

    deleteCategory(category) {
      RejectReasonService.deleteCategory(category.id)
        .then((response) => this.handleCategoryDeletionResponse(response, category))
        .catch((error) => this.handleDeletionError(error.response));
    },

    handleCategoryDeletionResponse(httpResponse, category) {
      if (httpResponse.status === 204) {
        this.fetchAvailableRejectReasons();
        const successMessage = this.$t("rejectReason.successfullyCategoryDeleted", {
          name: category.code + " - " + category.name,
        });
        const parentCategoryId = this.selectedElementParent.id;
        this.onSuccessfulEditionOperation(successMessage, parentCategoryId);
      } else {
        this.handleDeletionError(httpResponse);
      }
    },

    deleteReason(reason) {
      RejectReasonService.deleteReason(reason.id)
        .then((response) => this.handleReasonDeletionResponse(response, reason))
        .catch((error) => this.handleDeletionError(error.response));
    },

    handleReasonDeletionResponse(httpResponse, reason) {
      if (httpResponse.status === 204) {
        this.fetchAvailableRejectReasons();
        const successMessage = this.$t("rejectReason.successfullyReasonDeleted", {
          name: reason.code + " - " + reason.name,
        });
        const parentCategoryId = this.selectedElementParent.id;
        this.onSuccessfulEditionOperation(successMessage, parentCategoryId);
      } else {
        this.handleDeletionError(httpResponse);
      }
    },

    handleDeletionError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },

    onSuccessfulEditionOperation(successMessage, treeElementIdToExpand) {
      this.loadTree().then(() => {
        this.showOperationSuccess(successMessage);
        this.$refs.myFinder.expand(treeElementIdToExpand);
      });
    },

    getReasonCreationUrl() {
      if (this.selectedElement && this.selectedElement.type === "category") {
        return RouteService.toRejectReasonsCreation(this.selectedElement.id);
      }
      return RouteService.toHome();
    },
    getCategoryCreationUrl() {
      if (this.selectedElement) {
        return RouteService.toRejectCategoryCreation(this.selectedElement.id);
      }
      return RouteService.toHome();
    },
    getEditionUrl() {
      if (this.selectedElement) {
        if (this.selectedElement.type === "category") {
          return this.getCategoryEditionUrl();
        }
        if (this.selectedElement.type === "reason") {
          return this.getReasonEditionUrl();
        }
      }
      return RouteService.toHome();
    },
    getReasonEditionUrl() {
      if (this.selectedElement) {
        return RouteService.toRejectReasonEdition(this.selectedElement.id);
      }
      return RouteService.toHome();
    },
    getCategoryEditionUrl() {
      if (this.selectedElement) {
        return RouteService.toRejectCategoryEdition(this.selectedElement.id);
      }
      return RouteService.toHome();
    },
  },
  mounted() {
    if (this.activeFactory) {
      this.loadTree().then(() => {
        if (this.$route.query.expand) {
          this.$refs.myFinder.expand(this.$route.query.expand);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.tree-container {
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-lg);
  border: 1px solid var(--color-border-theme);
  background-color: var(--color-element-layer1);
  box-shadow: var(--box-shadow-small-elevation);

  &:empty {
    display: none;
  }

  ::v-deep .item:not(.expanded):hover {
    background-color: var(--color-control-background-theme);
  }

  ::v-deep .list-container {
    height: auto;
  }

  ::v-deep .inner-item {
    display: flex;
    align-items: center;

    .v-icon {
      margin-right: 8px;
    }
  }

  ::v-deep .item.expanded {
    // items within primary color
    .v-icon {
      color: White;
    }
  }
}

.action-container {
  display: flex;
  margin-bottom: 20px;

  .v-input {
    margin-right: auto;
  }
}
</style>
